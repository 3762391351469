@import "../compiled-variables.scss";

ion-header {
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);

    ion-toolbar {
        display: flex;
        align-items: center;
        --background: var(--ion-color-light);

        ion-buttons {
            display: flex;
            width: 100%;

            .dismiss-button {
                margin-left: auto;
            }

            .show-back-button {
                --color: var(--ion-color-medium-tint);
            }

            ion-button,
            ion-back-button,
            ion-menu-button,
            ion-menu-button.button,
            ion-button.button-clear,
            ion-button.button-clear.button,
            ion-button.button,
            ion-back-button.button,
            ion-back-button.button-clear,
            ion-back-button.button-clear.button {
                --color: var(--ion-color-light-contrast);
            }

            .dismiss {
                margin-left: auto;
            }
        }
    }

    &.--loading {
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            content: "";
            width: 30%;
            height: 3px;
            background: var(--ion-color-primary);
            background-size: 100%;
            animation: loading-bar 1s ease-in-out infinite;
            transform-origin: left;
            z-index: 100;
        }
    }
    @media (min-width: $breakpoint-md) {
        box-shadow: none;
        ion-toolbar {
            --background: var(--ion-background-color);
        }
    }
}

@keyframes loading-bar {
    from,
    to {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(250%);
    }
}
