@import "./variables.scss";
@import "./ionic/ion-header.scss";
@import "./ionic/ion-split-pane.scss";
@import "./ionic/ion-content.scss";
@import "./quill.core.scss";
@import "./quill.snow.scss";

* {
    font-family: var(--font-family) !important;
}

body {
    background-color: var(--ion-background-color);
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
}
