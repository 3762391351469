// http://ionicframework.com/docs/theming/
@import "./theme/transfonter.scss";
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import "@ionic/angular/css/display.css";
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";
@import "../../../shared/libs/styles/v1/index.scss";
@import "../../../shared/libs/styles/v2/index.scss";
@import "./theme/ionic/ion-header.scss";

* {
    --font-family: oek;
}
