@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-Bold.ttf") format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-ExtraLight.ttf") format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-Regular.ttf") format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: auto;
}

@font-face {
    font-family: "oek";
    src: url("/assets/fonts/ReadexPro-SemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: auto;
}
