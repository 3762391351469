@mixin skeleton($minHeight: 15px, $width: 100%, $colorRGB: --ion-color-medium-rgb, $marginBottom: 4px) {
    .--skeleton & {
        &[src=""],
        &:empty:not(img) {
            width: $width;
            min-height: $minHeight;
            background: linear-gradient(
                to right,
                rgba(var($colorRGB), 0.1) 0%,
                rgba(var($colorRGB), 0.4) 49%,
                rgba(var($colorRGB), 0.1) 80%
            );
            background-size: 100% 100%;
            margin-bottom: $marginBottom;
            animation-name: shimer;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
            animation-duration: 1.5s;
            opacity: 1;
        }
    }
}

@mixin truncate-text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin truncate-text-multi-line($font-size, $line-height, $lines-to-show) {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
    display: -webkit-box;
    font-size: calc(#{$font-size} * 1px);
    max-height: calc(#{$font-size} * #{$line-height} * #{$lines-to-show} * 1px);
    line-height: $line-height;
    overflow: hidden;
    text-overflow: ellipsis;
}

@keyframes shimer {
    from {
        background-position: -100px 0;
    }
    50% {
        background-position: 50px 0%;
    }
    to {
        background-position: -100px 0;
    }
}
