:root {
    //custom
    --ion-custom-color-input-border: #e7e7e7;
    --ion-custom-color-header: #fafafa;
    --ion-custom-color-list-item-border: #cbcbcb;
    --ion-custom-color-border-footer: #aeaeae;
    --ion-custom-color-side-bar-nav-border: #aaaaaa;
    --ion-custom-color-facebook: #3b5998;
    --shared-button-border-radius: 5px;

    //login
    --ion-custom-color-login-primary: var(--ion-color-primary);
    --ion-custom-color-separator-line: #e7e7e7;

    // Font
    --font-family: "avenir", sans-serif;

    // Border radius
    --border-radius-big: 1rem;
    --border-radius-middle: 0.5rem;
    --border-radius-small: 0.3rem;

    // Custom variables
    --ion-background-color: #f6f6f6;
    --padding: 13px;
    --min-height-header: 64px;
    --bottom-tabs-height: calc(var(--padding) / 2 + 50px);

    @media (max-width: 321px), (max-height: 641px) {
        --padding: 8px;
        --min-height-header: 50px;
    }
}

body {
    &.--transparent {
        background-color: transparent;
    }
}
