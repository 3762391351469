@import "../v1/vendors.scss";

@mixin font-title($color: --ion-color-dark, $text-align: center) {
    color: var($color);
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 1;
    text-align: $text-align;
    margin: 2px 0 0 0;
}

@mixin font-regular($color: --ion-color-dark, $text-align: left) {
    color: var($color);
    font-family: "Avenir", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 1.5;
    text-align: $text-align;
    margin: 2px 0 0 0;
}

@mixin font-regular-title($color: --ion-color-dark, $text-align: left) {
    color: var($color);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.2;
    text-align: $text-align;
    margin: 2px 0 0 0;
}

@mixin font-card-title($color: --ion-color-dark, $text-align: left) {
    color: var($color);
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.2;
    text-align: $text-align;
    margin: 2px 0 0 0;
}

@mixin font-big-title($color: --ion-color-dark, $text-align: left) {
    color: var($color);
    font-style: normal;
    font-weight: 500;
    font-size: 36px;
    line-height: 1.2;
    margin: 0;
}

@mixin font-mini-title($color: --ion-color-dark, $text-align: left) {
    color: var($color);
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.2;
    margin: 2px 0 0 0;
}
