ion-modal {
    @media (max-width: 500px) {
        &.half-screen {
            --height: 72%;
            align-items: flex-end;
        }
    }
    @media only screen and (min-height: 600px) and (min-width: 768px) {
        &.sc-ion-modal-md-h,
        &.sc-ion-modal-ios-h .modal-wrapper.sc-ion-modal-ios {
            --border-radius: 10px;
        }
    }

    &.auto-height {
        --height: auto;
        --max-width: min(400px, 100%);
        --border-radius: 40px;

        &.center {
            --border-radius: 10px;
            --max-width: min(400px, 90%);
        }
    }

    &.datetime-modal {
        $height: 250px;

        align-items: flex-end;

        --height: #{$height};

        ion-datetime {
            height: $height;
            max-width: 100%;
        }
    }
}
