@import "../compiled-variables.scss";

.ion-split-pane {
    --border: 0;
    width: 100%;
    max-width: $breakpoint-md;
    left: 50%;
    transform: translateX(-50%);

    @media (min-width: $breakpoint-lg) {
        transform: translateX(-62%);
    }
}
