ion-header {
    ion-toolbar {
        padding: 0;

        ion-buttons:last-child {
            max-width: 48px;
        }
        ion-buttons:first-child {
            max-width: 100%;
            ion-title {
                max-width: 100%;
                font-size: 24px;
                &.md {
                    padding: 0;
                }
            }

            ion-title:nth-child(1) {
                padding-inline-start: 48px;
            }
        }
    }
}

.filter-toolbar {
    &__slides {
        &__slide {
            ion-button {
                min-width: 150px;
            }
        }
    }
}

.filter-toolbar.--is-switch {
    .filter-toolbar {
        &__slides {
            &__slide {
                &:first-child {
                    ion-button {
                        --border-radius: 17.5px 0 0 17.5px;
                    }
                }
                &:last-child {
                    ion-button {
                        --border-radius: 0 17.5px 17.5px 0;
                    }
                }
            }
        }
    }
}
