@import "../vendors.scss";

ion-action-sheet {
    .action-sheet-button {
        &[class*="--flag-"] {
            &::before {
                position: absolute;
                left: var(--padding);
                content: "";

                $size: 32px;
                width: $size;
                height: $size;

                background-size: contain;
                background-position: top;
                background-repeat: no-repeat;
            }

            &::after {
                background: none;
            }

            &.--flag-de::before {
                background-image: url("https://flagcdn.com/w40/de.jpg");
            }
            &.--flag-at::before {
                background-image: url("https://flagcdn.com/w40/at.jpg");
            }
            &.--flag-ch::before {
                background-image: url("https://flagcdn.com/w40/ch.jpg");
            }
            &.--flag-it::before {
                background-image: url("https://flagcdn.com/w40/it.jpg");
            }
            &.--flag-fr::before {
                background-image: url("https://flagcdn.com/w40/fr.jpg");
            }
            &.--flag-nl::before {
                background-image: url("https://flagcdn.com/w40/nl.jpg");
            }
        }
    }
}
