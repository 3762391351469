@import "../vendors.scss";

ion-header {
    box-shadow: none;

    ion-toolbar {
        --background: var(--ion-color-secondary);
        --min-height: var(--min-height-header);

        ion-title {
            @include font-title(--ion-color-dark, left);
            line-height: 1.2;
            padding-inline-start: 48px;
            padding-inline-end: var(--padding);
            padding-top: 6px;
        }

        ion-buttons {
            ion-title {
                @include font-title(--ion-color-dark, left);
                line-height: 1.2;
                padding-inline-start: 48px;
                padding-inline-end: var(--padding);
                padding-top: 6px;

                &.md {
                    padding-inline-start: 32px;
                }
            }

            ion-button,
            ion-back-button,
            ion-menu-button,
            ion-menu-button.button,
            ion-button.button-clear,
            ion-button.button-clear.button,
            ion-button.button,
            ion-back-button.button,
            ion-back-button.button-clear,
            ion-back-button.button-clear.button {
                --color: var(--ion-color-dark);
            }
        }
    }

    &.--shadow {
        box-shadow: 0 4px 4px rgba(var(--ion-color-rgb), 0.2);
    }

    &.--loading {
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            display: block;
            content: "";
            width: 30%;
            height: 3px;
            background: var(--ion-color-primary);
            background-size: 100%;
            animation: loading-bar 1s ease-in-out infinite;
            transform-origin: left;
            z-index: 100;
        }
    }
    @media (min-width: $breakpoint-md) {
        ion-toolbar {
            --background: var(--ion-background-color);
        }
    }
}

@keyframes loading-bar {
    from,
    to {
        transform: translateX(0%);
    }
    50% {
        transform: translateX(250%);
    }
}
