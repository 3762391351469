@import "./variables.scss";
@import "./compiled-variables.scss";
@import "./ionic/ion-header.scss";
@import "./ionic/ion-item.scss";
@import "./ionic/ion-modal.scss";
@import "./ionic/ion-content.scss";
@import "./ionic/ion-split-pane.scss";
@import "./ionic//ion-action-sheet.scss";

.--disabled-greyed-out {
    filter: grayscale(1);
    cursor: default;
}
