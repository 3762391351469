:root {
    // primary
    --ion-color-primary: #c0262c;
    --ion-color-primary-rgb: 192, 38, 44;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #a92127;
    --ion-color-primary-tint: #c63c41;

    //secondary
    --ion-color-secondary: #007da0;
    --ion-color-secondary-rgb: 0, 125, 160;
    --ion-color-secondary-contrast: #ffffff;
    --ion-color-secondary-contrast-rgb: 255, 255, 255;
    --ion-color-secondary-shade: #006e8d;
    --ion-color-secondary-tint: #1a8aaa;

    //tertiary
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;

    //warning
    --ion-color-warning: #ffce00;
    --ion-color-warning-rgb: 255, 206, 0;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 255, 255, 255;
    --ion-color-warning-shade: #e0b500;
    --ion-color-warning-tint: #ffd31a;

    //dark
    --ion-color-dark: #212121;
    --ion-color-dark-rgb: 33, 33, 33;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #1d1d1d;
    --ion-color-dark-tint: #373737;

    //light
    --ion-color-light: #fafafa;
    --ion-color-light-rgb: 250, 250, 250;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #dcdcdc;
    --ion-color-light-tint: #fbfbfb;

    //text
    --ion-color-text: #474747;
    --ion-color-text-rgb: 71, 71, 71;
    --ion-color-text-contrast: #ffffff;
    --ion-color-text-contrast-rgb: 255, 255, 255;
    --ion-color-text-shade: #3e3e3e;
    --ion-color-text-tint: #595959;

    //light text
    --ion-color-light-text: #595959;
    --ion-color-light-text-rgb: 89, 89, 89;
    --ion-color-light-text-contrast: #ffffff;
    --ion-color-light-text-contrast-rgb: 255, 255, 255;
    --ion-color-light-text-shade: #4e4e4e;
    --ion-color-light-text-tint: #6a6a6a;

    //danger
    --ion-color-danger: #6f082d;
    --ion-color-danger-rgb: 111, 8, 45;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #620728;
    --ion-color-danger-tint: #7d2142;

    //success
    --ion-color-success: #3b9627;
    --ion-color-success-rgb: 59, 150, 39;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 255, 255, 255;
    --ion-color-success-shade: #348422;
    --ion-color-success-tint: #4fa13d;

    //medium
    --ion-color-medium: #989aa2;
    --ion-color-medium-rgb: 152, 154, 162;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #86888f;
    --ion-color-medium-tint: #a2a4ab;

    //custom
    --ion-custom-color-input-border: #eeeeee;
    --ion-custom-color-header: #fafafa;
    --ion-custom-color-list-item-border: #cccccc;
    --ion-custom-color-border-footer: #aeaeae;
    --ion-custom-color-side-bar-nav-border: #aaaaaa;
    --ion-custom-color-facebook: #3b5998;

    //login
    --ion-custom-color-login-primary: #808080;
    --ion-custom-color-login-secondary: #808080;
    --ion-custom-color-separator-line: #c4c3d0;

    // Font
    --font-family: sans-serif;

    // Border radius
    --border-radius-big: 1rem;
    --border-radius-middle: 0.5rem;
    --border-radius-small: 0.3rem;

    // Custom variables
    --ion-background-color: #ffffff;
    --padding: 13px;
    --shared-button-border-radius: 25px;
}
